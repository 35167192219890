import {
  Box,
  Button,
  ButtonProps,
  Flex,
  HStack,
  Modal,
  ModalCloseButton,
  ModalHeader,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useBreakpointValue,
  useDisclosure,
  useToken,
} from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowDown,
  faArrowRight,
  faArrowRotateRight,
  faArrowUp,
  faCheck,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faMinus,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkillSupportMaterial } from '@sparx/api/apis/sparx/content/v2/skill';
import {
  Activity,
  SkillActivity_State,
  SkillActivity_Steps_Delivery,
} from '@sparx/api/apis/sparx/science/packages/v1/activity';
import { TaskItem, TaskItem_Status } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { shouldRequireAudioStepRepeat, useSteps } from '@sparx/packageactivity';
import * as annotations from '@sparx/packageactivity/src/annotations';
import {
  checkInput,
  hasInput,
  IInput,
  MarkdownNode,
  MediaInputSettings,
  QuestionPasteEvent,
  SparxQuestion,
  SparxQuestionColours,
  SparxQuestionWrapper,
} from '@sparx/question';
import { useMutation } from '@tanstack/react-query';
import {
  reloadActivity,
  useActivityAction,
  useActivityNotify,
  useSubmitAnswer,
  useSubmitNext,
} from 'api/packages';
import { useClientEvent } from 'components/ClientEventProvider';
import { PeriodicTableModal } from 'components/periodictable/PeriodicTable';
import { getAssetUrl, uploadedAssetProvider } from 'components/uploadedasset/UploadedAsset';
import { VideoModal } from 'components/video/VideoModal';
import { AnimatePresence, motion } from 'framer-motion';
import debounce from 'lodash.debounce';
import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { KeyPressCallbacks, useKeyPress } from 'utils/hooks/keypress';
import { getQuestionMarkingMode } from 'utils/question';
import { isTaskItemRapidFire } from 'utils/rapidfire';
import {
  MultistepContinuousWrapper,
  MultistepSteppedWrapper,
  PreviousActivity,
} from 'views/task/MultistepDelivery';
import { TalkAndLearnTimer } from 'views/task/TalkAndLearnTimer';

import { SupportMaterial } from './SupportMaterial';

const QUESTION_RENDERER_FONT_SIZE = '18';

interface ActivityDeliveryWithUnloadProps {
  activity: Activity;
  taskItem: TaskItem;
  canSkip: boolean;
  onFinish: () => void;
  onContinue: (skip?: boolean) => void;
  hidePeriodicTable?: boolean;
  showTaskItemTitle?: boolean;
}

/**
 * ActivityDeliveryWithUnload is like ActivityDelivery but will send view
 * action events for the activity when the user navigates away from the page,
 * and provides the activity reload behaviour.
 */
export const ActivityDeliveryWithUnload = ({
  activity,
  taskItem,
  ...activityDeliveryProps
}: ActivityDeliveryWithUnloadProps) => {
  // Send load and unload events when navigating between questions
  const viewAction = useActivityNotify();
  const activityName = activity.name;
  const activityEvaluation = activity.state?.skillActivity?.evaluation;
  const activityAnswered =
    activityEvaluation?.status === TaskItem_Status.TASK_ITEM_STATUS_UNSPECIFIED;
  useEffect(() => {
    if (activityName && !activityAnswered) {
      viewAction.mutate({ activityName, action: { oneofKind: 'view', view: { unload: false } } });
      return () => {
        viewAction.mutate({ activityName, action: { oneofKind: 'view', view: { unload: true } } });
      };
    }
  }, [viewAction.mutate, activityName, activityAnswered]);

  // We wrap the activity reload in a debounce as there is no loading spinner on it.
  const throttledReloadActivity = useMemo(
    () => debounce(() => reloadActivity(taskItem.name), 500, { leading: true }),
    [taskItem.name],
  );

  const [mediaPlayed, setMediaPlayed] = useState(false);

  return (
    <ActivityDelivery
      activity={activity}
      key={activity.name}
      taskItem={taskItem}
      onRetry={() => activity && throttledReloadActivity()}
      {...activityDeliveryProps}
      onMediaPlayed={() => setMediaPlayed(true)}
      autoPlayMedia={mediaPlayed}
    />
  );
};

interface ActivityDeliveryProps {
  activity: Activity;
  taskItem?: TaskItem;

  onContinue?: (skip?: boolean) => void;
  onRetry?: () => Promise<void>;
  canSkip?: boolean;
  hidePeriodicTable?: boolean;
  showTaskItemTitle?: boolean;

  autoPlayMedia?: boolean;
  onMediaPlayed?: () => void;
}

export const ActivityDelivery = ({
  activity,
  taskItem,
  onContinue,
  onRetry: _onRetry,
  canSkip,
  hidePeriodicTable,
  autoPlayMedia,
  onMediaPlayed,
  showTaskItemTitle,
}: ActivityDeliveryProps) => {
  const { sendEvent } = useClientEvent();
  const activityAction = useActivityAction(activity);
  const submitAnswer = useSubmitAnswer(activityAction.mutateAsync);
  const { mutateAsync: onRetry, isLoading: isLoadingRetry } = useMutation({ mutationFn: _onRetry });
  const isLoading = activityAction.isLoading || submitAnswer.isLoading || isLoadingRetry;

  const next = useSubmitNext(activityAction.mutate);
  const isRapidFire = isTaskItemRapidFire(taskItem);

  const hasSupport = annotations.hasSupport(activity.annotations);
  const isTalkAndLearn = annotations.isTalkAndLearn(activity.annotations);
  const isNonTALSupport = annotations.isNonTALSupport(activity.annotations);
  const isTALTransition = annotations.isTalkAndLearnTransition(activity.annotations);

  const onReport = () => {
    activityAction.mutate({ action: { oneofKind: 'report', report: {} } });
  };
  const onPaste = (e: QuestionPasteEvent) => {
    sendEvent({ category: 'activity_paste', action: 'paste' }, { ...e, activity: activity.name });
  };

  // Input is on the last step
  const colours = useSparxQuestionColours();

  // Decide on the border colour
  const borderColor = getBorderColour(activity, isTalkAndLearn, isNonTALSupport);

  const {
    skillActivity,
    steps,
    input,
    getStepInput,
    setInput,
    submitDisabled,
    isSecondChance,
    activeStep,
    visibleStep,
    getStepProps,
  } = useSteps(activity);

  // If we are in stepped delivery and we revist the previous audio step we
  // should force the user to listen to it. Only applied to audio in the current
  // This is done by resetting the input for the step.
  const requireAudioStepRepeat = shouldRequireAudioStepRepeat({
    visibleStep,
    activeStep,
    skillActivity,
    getStepInput,
  });
  const [lastStep, setLastStep] = useState(-1);
  useEffect(() => {
    // We only want to do this if we have changed step
    if (lastStep !== visibleStep) {
      setLastStep(visibleStep);

      if (requireAudioStepRepeat) {
        const newInput: IInput = {
          ...getStepInput(visibleStep),
          media_fields: {},
        };
        for (const key in getStepInput(visibleStep)?.media_fields) {
          newInput.media_fields![key] = {};
        }
        setInput(newInput, visibleStep);
      }
    }
  }, [visibleStep, lastStep, requireAudioStepRepeat, getStepInput, setInput]);

  const getStep = (i: number) => {
    const questionMarkingMode = getQuestionMarkingMode(activity, steps[i].evaluation);
    const visibleInput = getStepInput(i) || {};
    const isAudioStep = Object.keys(visibleInput?.media_fields || {}).length === 1;

    let readOnly =
      isLoading || skillActivity?.state !== SkillActivity_State.QUESTION_ANSWER || i !== activeStep;

    let mediaSettings: MediaInputSettings | undefined;
    if (isAudioStep) {
      const autoComplete = visibleStep === activeStep;
      readOnly = !autoComplete && !requireAudioStepRepeat;
      mediaSettings = {
        autoPlay: autoPlayMedia,
        autoComplete,
        onPlay: onMediaPlayed,
      };
    }

    return (
      <SparxQuestion
        {...getStepProps(i)}
        readOnly={readOnly}
        colours={colours}
        onPaste={onPaste}
        getUploadedAsset={uploadedAssetProvider}
        getAssetUrl={getAssetUrl}
        fontSize={QUESTION_RENDERER_FONT_SIZE}
        sendAnalyticEvent={(action, labels) =>
          sendEvent({ category: 'question', action }, { activityName: activity.name, ...labels })
        }
        questionMarkingMode={questionMarkingMode}
        mediaInputSettings={mediaSettings}
      />
    );
  };

  let content: React.ReactNode | undefined;
  let afterContent: React.ReactNode | undefined;
  if (skillActivity?.question?.steps?.delivery === SkillActivity_Steps_Delivery.STEPPED) {
    content = (
      <MultistepSteppedWrapper
        currentStep={visibleStep}
        steps={steps}
        maxStep={skillActivity.question.steps.maxStep}
        stepToDisplayNum={skillActivity.question.steps.stepDisplayNumberMap}
      >
        {getStep}
      </MultistepSteppedWrapper>
    );
  } else if (skillActivity?.question?.steps?.delivery === SkillActivity_Steps_Delivery.CONTINUOUS) {
    content = (
      <>
        {activity.state?.previousActivity && (
          <PreviousActivity activity={activity.state.previousActivity} />
        )}
      </>
    );
    afterContent = (
      <MultistepContinuousWrapper
        currentStep={visibleStep}
        steps={steps}
        isSupport={isNonTALSupport}
        color={borderColor}
        maxStep={skillActivity.question.steps.maxStep}
        stepToDisplayNum={skillActivity.question.steps.stepDisplayNumberMap}
      >
        {getStep}
      </MultistepContinuousWrapper>
    );
  } else {
    content = getStep(activeStep);

    if (isRapidFire && skillActivity?.question?.supportMaterial) {
      afterContent = <RapidFireSupportMaterial material={skillActivity.question.supportMaterial} />;
    }
  }

  const onOkayIveGotIt = () => {
    setInput(steps[activeStep].input); // clear input
    next('next');
  };

  const iDontKnow = async () => {
    if (activityAction.mutateAsync && !isLoading) {
      await activityAction.mutateAsync({
        action: { oneofKind: 'skip', skip: {} },
        skipActivityUpdate: !isRapidFire,
      });
      !isRapidFire && (await onRetry());
    }
  };

  // If the task item is incorrect then we should show a button to report a problem
  const showReportProblem =
    skillActivity?.evaluation?.status === TaskItem_Status.INCORRECT ||
    skillActivity?.state === SkillActivity_State.STEP_RESULT;
  // We want to display things slightly diferently based on screen width and whether
  // we are showing the report a problem button. Create the booleans that control those here.
  const displayState = useBreakpointValue<{ tryAgainHideIcon?: boolean; tryAgainShort?: boolean }>(
    {
      base: { tryAgainHideIcon: showReportProblem, tryAgainShort: showReportProblem },
      sm: { tryAgainShort: showReportProblem },
      md: {},
    },
    { ssr: false },
  );

  const {
    isOpen,
    onOpen: getHelp,
    onClose,
  } = useDisclosure({
    onOpen: () => activityAction.mutate({ action: { oneofKind: 'help', help: { viewing: true } } }),
    onClose: () =>
      activityAction.mutate({ action: { oneofKind: 'help', help: { viewing: false } } }),
  });

  const buttonProps = { colorScheme: 'buttonTeal', size: ['sm', 'md'], isLoading };

  const buttonMap = {
    okayIGotThis: (
      <Button
        onClick={onOkayIveGotIt}
        rightIcon={<FontAwesomeIcon icon={faChevronRight} fixedWidth={true} />}
        {...buttonProps}
      >
        Okay, I&apos;ve got it
      </Button>
    ),
    showMeFactAgain: (
      <Button variant="outline" onClick={() => next('retry')} {...buttonProps}>
        <Text as="span">
          Show me the support
          <Text as="span" display={['none', 'inline']}>
            {' '}
            again
          </Text>
        </Text>
      </Button>
    ),
    submitAnswer: (title = 'Submit', dir: 'down' | 'right' = 'right') => (
      <Button
        onClick={() => submitAnswer.mutate(input)}
        rightIcon={
          <FontAwesomeIcon
            icon={dir === 'down' ? faChevronDown : faChevronRight}
            fixedWidth={true}
          />
        }
        isDisabled={submitDisabled}
        {...buttonProps}
      >
        {title}
      </Button>
    ),
    nextQuestion: (
      <Button
        onClick={() => onContinue?.()}
        rightIcon={<FontAwesomeIcon icon={faChevronRight} fixedWidth={true} />}
        {...buttonProps}
      >
        Next
      </Button>
    ),
    skipQuestion: (
      <Button onClick={() => onContinue?.(true)} variant="ghost" {...buttonProps}>
        Skip
      </Button>
    ),
    tryAgain: (
      <Button
        onClick={() => onRetry()}
        leftIcon={
          displayState?.tryAgainHideIcon || isRapidFire ? undefined : (
            <FontAwesomeIcon icon={faArrowRotateRight} fixedWidth={true} />
          )
        }
        rightIcon={
          isRapidFire ? <FontAwesomeIcon icon={faChevronRight} fixedWidth={true} /> : undefined
        }
        {...buttonProps}
      >
        {isRapidFire ? (
          'Next'
        ) : isTALTransition ? (
          displayState?.tryAgainShort ? (
            'Learn this'
          ) : (
            <>Let&apos;s learn this</>
          )
        ) : (
          'Try again'
        )}
      </Button>
    ),
    tryStepAgain: (onClick: () => void) => (
      <Button
        onClick={onClick}
        leftIcon={<FontAwesomeIcon icon={faArrowRotateRight} fixedWidth={true} />}
        {...buttonProps}
      >
        Try again
      </Button>
    ),
    getHelp: (
      <Button onClick={getHelp} variant="outline" {...buttonProps}>
        Get help
      </Button>
    ),
    iDontKnow: (
      <Button onClick={iDontKnow} variant="outline" {...buttonProps}>
        I don&apos;t know
      </Button>
    ),
    toNextStep: (
      onClick: () => void,
      isDisabled: boolean = false,
      onDisabledClick: () => void = () => {},
    ) => (
      <Button
        onClick={() => (isDisabled ? onDisabledClick() : onClick())}
        rightIcon={<FontAwesomeIcon icon={faChevronDown} />}
        aria-disabled={isDisabled}
        {...buttonProps}
      >
        Next
      </Button>
    ),
    toPreviousStep: (onClick: () => void) => (
      <Button onClick={onClick} leftIcon={<FontAwesomeIcon icon={faChevronUp} />} {...buttonProps}>
        Previous
      </Button>
    ),
  };

  const stepped = skillActivity?.question?.steps?.delivery === SkillActivity_Steps_Delivery.STEPPED;
  const continuous =
    skillActivity?.question?.steps?.delivery === SkillActivity_Steps_Delivery.CONTINUOUS;

  let header: ReactNode;
  let headerInScrollable: ReactNode;
  let buttons: ReactNode;
  let keypresses: KeyPressCallbacks = {};
  switch (skillActivity?.state) {
    case SkillActivity_State.TAL_START: {
      header = <HeaderText>Let&apos;s learn it...</HeaderText>;
      buttons = buttonMap.okayIGotThis;
      content = skillActivity?.question?.supportMaterial ? (
        <SupportMaterial
          material={skillActivity.question.supportMaterial}
          inline={true}
          fontSize={QUESTION_RENDERER_FONT_SIZE}
        />
      ) : null;
      keypresses = { Enter: onOkayIveGotIt };
      break;
    }
    case SkillActivity_State.TAL_TIMER: {
      header = <HeaderText>Let&apos;s learn it...</HeaderText>;
      buttons = (
        <>
          <FooterButtonGroup />
          <FooterButtonGroup>{buttonMap.showMeFactAgain}</FooterButtonGroup>
          <FooterButtonGroup />
        </>
      );
      content = (
        <div>
          <Text fontSize="lg">Think about what you&apos;ve learnt...</Text>
          <TalkAndLearnTimer time={3} onContinue={() => !isLoading && next('next')} />
        </div>
      );
      break;
    }
    case SkillActivity_State.QUESTION_ANSWER: {
      const onSubmitAnswer = () =>
        checkInput(input) ? submitAnswer.mutateAsync(input) : undefined;

      const questionSupport = (
        <FooterButtonGroup>
          {activity.state?.skillActivity?.question?.videoId ? (
            <VideoModal
              videoID={activity.state.skillActivity.question.videoId}
              activity={activity}
            />
          ) : (
            !hidePeriodicTable && <PeriodicTableModal eventContext={{ activity: activity.name }} />
          )}
        </FooterButtonGroup>
      );

      if (stepped || continuous) {
        const canPrevious = stepped && visibleStep > 0;
        const canNext = stepped && visibleStep < activeStep;
        const stepHasInput = hasInput(input);

        const toPreviousStep = () => {
          if (visibleStep > 0) {
            const navigateStep = { step: visibleStep - 1 };
            activityAction.mutate({ action: { oneofKind: 'navigateStep', navigateStep } });
          }
        };

        const toNextStep = () => {
          if (visibleStep < activeStep) {
            const navigateStep = { step: visibleStep + 1 };
            activityAction.mutate({ action: { oneofKind: 'navigateStep', navigateStep } });
          } else {
            onSubmitAnswer();
          }
        };

        const isAudioStep = Object.keys(getStepInput(visibleStep)?.media_fields || {}).length > 0;

        let nextButton: ReactNode;
        if (canNext || !stepHasInput) {
          if (requireAudioStepRepeat && !checkInput(getStepInput(visibleStep))) {
            // Show a message over the next button to to explain why it's disabled
            nextButton = (
              <>
                <Popover trigger="hover" placement="top">
                  <PopoverTrigger>{buttonMap.toNextStep(toNextStep, true)}</PopoverTrigger>
                  <Portal>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverBody textAlign="center">
                        Play the entire clip. It&apos;ll help you answer the next question.
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </Popover>
              </>
            );
          } else {
            nextButton = buttonMap.toNextStep(toNextStep);
          }
        } else {
          nextButton = buttonMap.submitAnswer(
            stepHasInput && !isAudioStep ? 'Submit' : 'Next',
            'down',
          );
        }

        if (isNonTALSupport && stepped) {
          header = <LetsLearnItHeader />;
        }
        buttons = (
          <>
            <FooterButtonGroup align="flex-end">{nextButton}</FooterButtonGroup>
            {questionSupport}
            <FooterButtonGroup align="flex-start">
              {canPrevious && buttonMap.toPreviousStep(toPreviousStep)}
            </FooterButtonGroup>
          </>
        );
        keypresses = { Enter: toNextStep };
      } else {
        if (isTalkAndLearn) {
          header = <CheckLearningHeader />;
        }
        buttons = (
          <>
            <FooterButtonGroup align="flex-end">{buttonMap.submitAnswer()}</FooterButtonGroup>
            {questionSupport}
            <FooterButtonGroup align="flex-start">
              {((hasSupport && !isTalkAndLearn) || isRapidFire) && buttonMap.iDontKnow}
              {isTalkAndLearn
                ? buttonMap.showMeFactAgain
                : skillActivity.question?.supportMaterial
                  ? buttonMap.getHelp
                  : undefined}
            </FooterButtonGroup>
          </>
        );
        keypresses = { Enter: onSubmitAnswer };
      }

      if (
        !header &&
        taskItem?.state?.status === TaskItem_Status.INCORRECT &&
        taskItem?.state.teacherSetStatus
      ) {
        header = <TeacherMarkedIncorrectHeader />;
      }

      if (!header && isSecondChance) {
        headerInScrollable = <SecondChanceHeader />;
      }

      break;
    }
    case SkillActivity_State.STEP_RESULT: {
      if (isNonTALSupport && stepped) {
        header = <LetsLearnItHeader />;
      }
      buttons = (
        <>
          <FooterButtonGroup align="flex-end">
            {buttonMap.tryStepAgain(async () => {
              // If we are in stepped delivery, and we have media steps, then take the user back to the previous media step.
              if (stepped) {
                const maxStep = activity.state?.skillActivity?.question?.steps?.maxStep || 0;
                let lastMediaIndex = -1;
                for (let i = maxStep; i >= 0; i--) {
                  if (Object.keys(getStepInput(i)?.media_fields || {}).length > 0) {
                    lastMediaIndex = i;
                    break;
                  }
                }
                if (lastMediaIndex >= 0 && lastMediaIndex !== activeStep) {
                  const navigateStep = { step: lastMediaIndex };
                  activityAction.mutate({ action: { oneofKind: 'navigateStep', navigateStep } });
                  return;
                }
              }

              next('next');
            })}
          </FooterButtonGroup>
          <FooterButtonGroup align="flex-start" />
        </>
      );
      keypresses = { Enter: () => next('next') };
      break;
    }
    case SkillActivity_State.RESULTS: {
      const isLLT = isNonTALSupport && stepped;
      header = isTalkAndLearn ? <CheckLearningHeader /> : isLLT ? <LetsLearnItHeader /> : undefined;

      if (taskItem?.state?.completed) {
        buttons = buttonMap.nextQuestion;
        keypresses = { Enter: () => onContinue?.() };
      } else {
        buttons = (
          <HStack spacing={4}>
            {canSkip && buttonMap.skipQuestion}
            {buttonMap.tryAgain}
          </HStack>
        );
        keypresses = { Enter: () => onRetry?.() };
      }

      if (
        !header &&
        taskItem?.state?.status === TaskItem_Status.CORRECT &&
        taskItem?.state.teacherSetStatus
      ) {
        header = <TeacherMarkedCorrectHeader />;
      }

      break;
    }
  }

  // For assessment packages show the item title if we have one
  let taskItemTitleHeader: ReactNode;
  if (showTaskItemTitle && taskItem?.title) {
    // If we have a border colour then we are in a state where we want to show
    // the title in the header container so the bg matches the border
    if (borderColor) {
      header = <HeaderText>{taskItem?.title}</HeaderText>;
    } else {
      taskItemTitleHeader = (
        <Box
          backgroundColor={`blue.200`}
          transition="background 0.4s, border-color 0.4s"
          px={[3, 4]}
          py={[2, 3]}
          mb={2}
          display="flex"
          alignItems="center"
          borderRadius="md"
        >
          <HeaderText>{taskItem?.title}</HeaderText>
        </Box>
      );
    }
  }

  useKeyPress(isLoading ? {} : keypresses);

  return (
    <ActivityContainer color={borderColor}>
      {header ? (
        <HeaderContainer color={borderColor}>{header}</HeaderContainer>
      ) : (
        taskItemTitleHeader
      )}
      <ScrollingWrapper
        // Set the key to the visible step to force Scrolling wrapper to reset when the step changes
        key={visibleStep}
      >
        {headerInScrollable}
        <QuestionPage afterQuestion={afterContent}>{content}</QuestionPage>
      </ScrollingWrapper>

      <ButtonContainer
        status={skillActivity?.evaluation?.status}
        skipped={taskItem?.state?.status === TaskItem_Status.SKIPPED}
        onReport={showReportProblem ? onReport : undefined}
        isRapidFire={isRapidFire}
      >
        {buttons}
      </ButtonContainer>
      {skillActivity?.question?.supportMaterial && (
        <SupportMaterialModal
          isOpen={isOpen}
          onClose={onClose}
          supportMaterial={skillActivity.question.supportMaterial}
        />
      )}
    </ActivityContainer>
  );
};

type ScrollPos = 'top' | 'middle' | 'bottom';
const ScrollOpaqueButtonHeight = 100;
const ScrollButtonHeightMultiplier = 1.5;

const ScrollingWrapper = ({ children }: PropsWithChildren) => {
  // Mutable values like 'ref.current' aren't valid dependencies
  // because mutating them doesn't re-render the component.
  // Instead, we use a state as a ref to be reactive.
  const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null);

  const [scrollPos, setScrollPos] = useState<ScrollPos>('top');
  const [showButtons, setShowButtons] = useState(false);
  const checkShowButtons = useCallback(
    (container: HTMLDivElement) => {
      if (container.scrollHeight > container.clientHeight * ScrollButtonHeightMultiplier) {
        setShowButtons(true);
      } else {
        setShowButtons(false);
      }
    },
    [setShowButtons],
  );

  // When the scrollRef is set check whether we need to scroll after a short time,
  // this seems to be the simplest way for initially displaying the buttons.
  // Attempts were made by using resize events but it was inconsistent, this
  // seems like a suitable solution without adding extra complexity monitoring
  // element sizes, especially given the buttons will also appear when any
  // scrolling happens
  useEffect(() => {
    if (scrollRef) {
      const container = scrollRef;
      const callback = () => {
        checkShowButtons(container);
      };
      const timeout = setTimeout(callback, 100);
      return () => clearTimeout(timeout);
    }
  }, [checkShowButtons, scrollRef]);

  const { sendEvent } = useClientEvent();
  const clickEvent = (
    direction: string,
    scrollHeight: number,
    scrollTop: number,
    containerHeight: number,
  ) => {
    sendEvent(
      { category: 'question_scroll_button', action: `clicked_to_${direction}` },
      {
        scrollHeight: scrollHeight.toString(),
        scrollTop: scrollTop.toString(),
        containerHeight: containerHeight.toString(),
      },
    );
  };

  return (
    <Box
      position="relative"
      flex={1}
      overflow="hidden"
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <Box
        p={[3, 4]}
        minHeight="100%"
        height="100%"
        overflowY="auto"
        overflowX="auto"
        position="relative"
        ref={setScrollRef}
        onScroll={e => {
          let pos: ScrollPos = 'top';
          if (e.currentTarget.scrollTop < ScrollOpaqueButtonHeight) {
            pos = 'top';
          } else if (
            e.currentTarget.scrollHeight - e.currentTarget.scrollTop <
            e.currentTarget.clientHeight + ScrollOpaqueButtonHeight
          ) {
            pos = 'bottom';
          } else {
            pos = 'middle';
          }
          setScrollPos(pos);
          checkShowButtons(e.currentTarget);
        }}
      >
        <Box
          minHeight="100%"
          position="relative"
          height="100%"
          display="flex"
          flexDirection="column"
        >
          {children}
        </Box>
      </Box>
      {showButtons && (
        <>
          <ScrollButton
            atExtreme={scrollPos === 'bottom'}
            atOppositeExtreme={scrollPos === 'top'}
            top={6}
            onClick={() => {
              if (scrollRef) {
                clickEvent(
                  'top',
                  scrollRef.scrollHeight,
                  scrollRef.scrollTop,
                  scrollRef.clientHeight,
                );
                scrollRef.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
              }
            }}
            rightIcon={<FontAwesomeIcon icon={faArrowUp} />}
          >
            To top
          </ScrollButton>
          <ScrollButton
            atExtreme={scrollPos === 'top'}
            atOppositeExtreme={scrollPos === 'bottom'}
            bottom={6}
            onClick={() => {
              if (scrollRef) {
                clickEvent(
                  'bottom',
                  scrollRef.scrollHeight,
                  scrollRef.scrollTop,
                  scrollRef.clientHeight,
                );
                scrollRef.scrollTo({
                  top: scrollRef.scrollHeight,
                  behavior: 'smooth',
                });
              }
            }}
            rightIcon={<FontAwesomeIcon icon={faArrowDown} />}
          >
            To bottom
          </ScrollButton>
        </>
      )}
    </Box>
  );
};

const ScrollButton = ({
  children,
  atExtreme,
  atOppositeExtreme,
  ...props
}: PropsWithChildren<ButtonProps & { atExtreme: boolean; atOppositeExtreme: boolean }>) => (
  <Button
    position="absolute"
    right={6}
    zIndex={99}
    variant="solid"
    colorScheme="buttonBlue"
    size="xs"
    opacity={atExtreme ? 1 : atOppositeExtreme ? 0 : 0.2}
    isDisabled={atOppositeExtreme}
    _hover={{ opacity: atOppositeExtreme ? 0 : 1, colorScheme: 'blue' }}
    cursor={atOppositeExtreme ? 'default' : undefined}
    pointerEvents={atOppositeExtreme ? 'none' : undefined}
    {...props}
  >
    {children}
  </Button>
);

const getBorderColour = (activity: Activity, isTalkAndLearn: boolean, isNonTALSupport: boolean) => {
  let borderColor;
  if (activity.state?.skillActivity?.evaluation?.status === TaskItem_Status.CORRECT) {
    borderColor = 'green';
  } else if (
    activity.state?.skillActivity?.evaluation?.status === TaskItem_Status.PENDING_CORRECT
  ) {
    borderColor = 'green';
  } else if (activity.state?.skillActivity?.evaluation?.status === TaskItem_Status.INCORRECT) {
    borderColor = 'red';
  } else if (isTalkAndLearn || isNonTALSupport) {
    borderColor = 'orange';

    // At the moment being on step result means they got the step wrong
    if (activity.state?.skillActivity?.state === SkillActivity_State.STEP_RESULT) {
      borderColor = 'red';
    }
  }
  return borderColor;
};

const ActivityContainer = ({ color, children }: PropsWithChildren<{ color?: string }>) => (
  <Box
    display="flex"
    flexDirection="column"
    height="100%"
    borderWidth={4}
    borderColor={color ? `${color}.200` : 'white'}
    transition="border-color 0.4s"
    rounded={['none', 'md']}
    overflow="hidden"
  >
    {children}
  </Box>
);

const HeaderContainer = ({ color, children }: PropsWithChildren<{ color?: string }>) => (
  <Box
    backgroundColor={color && `${color}.200`}
    transition="background 0.4s, border-color 0.4s"
    borderBottomColor={color ? `${color}.200` : 'white'}
    borderBottomWidth={4}
    px={[3, 4]}
    py={[2, 3]}
    display="flex"
    alignItems="center"
  >
    {children}
  </Box>
);

interface SupportMaterialModalProps {
  isOpen: boolean;
  onClose: () => void;
  supportMaterial: SkillSupportMaterial;
}

export const SupportMaterialModal = ({
  isOpen,
  onClose,
  supportMaterial,
}: SupportMaterialModalProps) => (
  <AnimatePresence>
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <Box
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        as={motion.div}
        position="fixed"
        inset="0 0 0 0"
        display="flex"
        flexDirection="column"
        overflowY="auto"
        backgroundColor="white"
      >
        <ModalHeader>Help</ModalHeader>
        <ModalCloseButton />
        <Box p={6} pt={1} flex={1} display="flex" flexDirection="column" overflowY="auto">
          <SupportMaterial material={supportMaterial} onClose={onClose} />
        </Box>
      </Box>
    </Modal>
  </AnimatePresence>
);

const HeaderText = ({ children }: { children: React.ReactNode }) => (
  <Text fontSize={['lg', 'xl']} fontWeight="bold" display="flex">
    {children}
  </Text>
);

const CheckLearningHeader = () => <HeaderText>Check your learning</HeaderText>;
const LetsLearnItHeader = () => <HeaderText>Let&apos;s learn it</HeaderText>;

const TeacherMarkedIncorrectHeader = () => (
  <Box
    mb={-4}
    py={2}
    px={3}
    width="100%"
    textAlign="center"
    bg="orange.100"
    color="orange.700"
    borderRadius="md"
  >
    Your teacher would like you to send another photo of your workings for this question.
  </Box>
);
const TeacherMarkedCorrectHeader = () => (
  <Box width="100%" textAlign="center" color="green.700">
    Your teacher has marked this question as correct. Well done!
  </Box>
);

interface ButtonContainerProps {
  children: React.ReactNode;
  status?: TaskItem_Status;
  skipped?: boolean;
  onReport?: () => void;
  isRapidFire?: boolean;
}

const ButtonContainer = ({
  children,
  skipped,
  status,
  onReport,
  isRapidFire,
}: ButtonContainerProps) => {
  const displayState = useBreakpointValue<{ iconOnly?: boolean; splitLine?: boolean }>(
    {
      base: { iconOnly: true, splitLine: true },
      sm: { iconOnly: !!onReport },
      md: {},
    },
    { ssr: false },
  );

  let content = <></>;
  let color = '';
  switch (status) {
    case TaskItem_Status.CORRECT:
      content = <ResultStatus color="green.500" text="Correct" icon={faCheck} />;
      color = 'green.50';
      break;
    case TaskItem_Status.PENDING_CORRECT:
      content = <ResultStatus color="green.500" text="Correct" icon={faCheck} />;
      color = 'green.50';
      break;
    case TaskItem_Status.SKIPPED:
      if (isRapidFire) {
        content = (
          <Flex>
            <ResultStatus
              color="orange.300"
              text=""
              icon={faMinus}
              iconOnly={displayState?.iconOnly}
            />
          </Flex>
        );
        color = 'orange.50';
        break;
      }
    // fallthrough
    case TaskItem_Status.INCORRECT:
      content = (
        <Flex>
          <ResultStatus
            color="red.500"
            text="Incorrect"
            icon={faTimes}
            iconOnly={displayState?.iconOnly}
          />
          {onReport && <ReportButton onReport={onReport} />}
        </Flex>
      );
      color = 'red.50';
      if (skipped) {
        content = <ResultStatus color="red.500" text="Skipped" icon={faArrowRight} />;
        color = 'red.50';
      }
      break;
    default:
      if (onReport) {
        content = <ReportButton onReport={onReport} />;
      }
  }
  return (
    <Box
      p={[3, 4]}
      borderTop="2px"
      borderTopColor="gray.100"
      bgGradient={`linear(to-r, ${color}, white)`}
      display="flex"
      justifyContent="space-between"
      flexDirection="row-reverse"
      alignItems="center"
    >
      {children}
      {content}
    </Box>
  );
};

const FooterButtonGroup = ({
  children,
  align = 'center',
}: {
  children?: React.ReactNode;
  align?: string;
}) => (
  <Flex flex={align === 'center' ? 0 : 1} justifyContent={align} px={align === 'center' ? 2 : 0}>
    {children}
  </Flex>
);

const ReportButton = ({ onReport }: { onReport: () => void }) => {
  const splitLine = useBreakpointValue<boolean>(
    {
      base: true,
      sm: false,
    },
    { ssr: false },
  );
  const [reported, setReported] = useState(false);

  return reported ? (
    <Text
      ml={4}
      fontSize="sm"
      color="gray.500"
      lineHeight="110%"
      alignSelf="center"
      fontWeight="bold"
      mt="1px"
    >
      Thank you{!splitLine && ' for reporting a problem'}
    </Text>
  ) : (
    <Button
      size="sm"
      variant="ghost"
      textDecoration="underline"
      color="gray.500"
      colorScheme="gray"
      px={2}
      ml={4}
      onClick={() => {
        setReported(true);
        onReport();
      }}
    >
      Report a{splitLine && <br />} problem
    </Button>
  );
};

export const useSparxQuestionColours = (): SparxQuestionColours => {
  const [buttonColour, correctBg, correctText, incorrectBg, incorrectText] = useToken('colors', [
    'blue.500',
    'green.200',
    'green.400',
    'red.200',
    'red.400',
  ]);

  return {
    buttonColor: buttonColour,
    correctBackground: correctBg,
    correctText: correctText,
    incorrectBackground: incorrectBg,
    incorrectText: incorrectText,
  };
};

interface ResultStatusProps {
  color: string;
  text: string;
  icon: IconDefinition;
  iconOnly?: boolean;
}

const ResultStatus = ({ color, text, icon, iconOnly }: ResultStatusProps) => (
  <HStack spacing={4}>
    <Box
      w={[8, 10]}
      h={[8, 10]}
      my={[-4, -5]}
      borderRadius="full"
      bg={color}
      display="flex"
      alignItems="center"
      justifyContent="center"
      color="white"
      fontSize="xl"
    >
      <FontAwesomeIcon icon={icon} />
    </Box>
    {!iconOnly && (
      <Text fontWeight="bold" fontSize={['md', 'lg']} color={color + '.900'}>
        {text}
      </Text>
    )}
  </HStack>
);

const QuestionPage = ({
  children,
  afterQuestion,
}: PropsWithChildren<{ afterQuestion?: React.ReactNode }>) => (
  <Box flex={1}>
    <Box display="flex" px={[1, 1, 1, 3]} py={[0, 0, 0, 2]} position="relative">
      {children}
    </Box>
    {afterQuestion}
  </Box>
);

const RapidFireSupportMaterial = ({ material }: { material: SkillSupportMaterial }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      const id = setTimeout(() => {
        if (ref.current) ref.current.scrollIntoView({ behavior: 'smooth' });
      }, 100);
      return () => clearTimeout(id);
    }
  }, [material, ref]);

  return (
    <Box>
      <AnimatePresence>
        <Box
          ref={ref}
          mx={3}
          borderRadius="md"
          borderWidth={2}
          borderColor="orange.200"
          overflow="hidden"
          as={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <Text bg="orange.200" p={4} fontSize={['lg', 'xl']} fontWeight="bold" display="flex">
            Let&apos;s learn it...
          </Text>
          <Box bg="white" p={4}>
            <SparxQuestionWrapper fontSize={QUESTION_RENDERER_FONT_SIZE}>
              <MarkdownNode>{material.text}</MarkdownNode>
            </SparxQuestionWrapper>
          </Box>
        </Box>
      </AnimatePresence>
    </Box>
  );
};

const SecondChanceHeader = () => (
  <Box
    px={3}
    py={2}
    bg="blue.50"
    width="100%"
    textAlign="center"
    color="blue.900"
    borderRadius="md"
  >
    <strong>Second chance!</strong> Review your working and see if you can correct your mistakes
  </Box>
);
